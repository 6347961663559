<template>
  <vx-card no-shadow >
    <div class="md:w-3/4 justify-between items-center">
      <div class="order-details">
        <h5 class="font-bold">Order: #{{order.number}}</h5>
        <p class="text-sm" style="color:#555;">Date: {{order.created_at | date_time(1)}}</p>
        <div class="my-3">
          <span v-if="order.status == 'completed'" class="text-sm"> {{order.completed_at | date(true) }} </span>
          <span v-if="order.status == 'refunded'" class="text-sm"> {{order.refunded_at | date(true) }} </span>
        </div>
        <ul class="summary-content">
          <li class="flex mb-2 justify-between">Status <strong class="" v-html="$store.getters['orderStatus'](order.status)"></strong></li>
          <li v-if="cart.coupon" class="flex mb-2 justify-between">Discount(-{{cart.coupon.percent}}%) <strong class=" price"> -US${{cart.coupon.discount | money_format}}</strong></li>
          <li class="flex mb-2 justify-between">Total price<strong class="price">{{order.currency_symbol}}{{order.amount | money_format}} <span class="uppercase">{{order.currency_code}}</span></strong></li>
          <li class="flex mb-2 justify-between">Delivery date<strong class="">{{$store.getters['deliveryDate'](order.created_at, order.delivery_time)}}</strong></li>
        </ul>
      </div>
    </div>
    <vs-divider/>
    <div class="flex justify-between">
      <span class="font-bold">for <a :href="absoluteUrl(`/${cart.product.slug}`)" class="cursor-pointer" target="_blank">{{cart.product.name }}</a> </span>
    </div>
    <div class="mt-3">
      <order-feature-list/>
    </div>
  </vx-card>
</template>

<script>

import OrderFeatureList from "./components/OrderFeatureList.vue"

export default {
  components: {
    OrderFeatureList,
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
  },
}

</script>

<style type="text/css" scoped>

    body,.text-sm{
        font-size: 12px;
    }

    sub{font-size: 12px;}

    #clockdiv{
        display: inline-block;
        float:left;
        margin-left:5px;
    }
    #clockdiv table .timedata td{
      background-color: #f8f8f8;
    }
    #clockdiv table .timedata td h4 {
        margin-bottom: 0px;
        color: #008b9c;
        font-size: 12px;
    }

    #clockdiv .notify-ex{
        font-size: 12px;
    }
    #clockdiv .table th, #clockdiv .table td{
        padding:3px 5px;
    }
    .price-holder{
        float:right;
        display:inline-block;
    }

    @media(max-width: 580px){
      .order-details{
        display: block;
      }
      .price-holder{
        display:block;
      }
    }
    .price-holder:first-child{
        font-size: 34px;
        color:#444;
        display: inline-block;
    }
    table thead tr{
        border-top:2px solid #ededed !important;
        background: #c2c6dc30 !important;
    }

    .table-responsive {
      display: block !important;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

      @media (max-width: 767.98px) {
        .table-responsive-md {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        .table-responsive-md > .table-bordered {
          border: 0;
        }
      }

      @media (max-width: 991.98px) {
        .table-responsive-lg {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        .table-responsive-lg > .table-bordered {
          border: 0;
        }
      }
    .icon-config{
      width:12px !important;
      height: 12px !important;
    }

</style>
