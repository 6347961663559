<template>
  <div class="order-delivery-show" v-if="delivery">
      <vs-popup
        @close="close"
        :title="shownFile.name"
        v-if="shownFile"
        fullscreen
        background-color-popup="rgb(205 205 205 / 99%)"
        :active.sync="openPopup">
        <div class="delivery-file">
          <div class="vx-row justify-center">
            <div class="vx-col mb-4 w-full sm:w-1/2">
              <VuePerfectScrollbar class="content-scroll-area cursor-pointer border border-solid d-theme-border-grey-light" :settings="settings">
                <img class="w-full rounded" :src="absoluteUrl(shownFile.path)" :alt="shownFile.name" />
              </VuePerfectScrollbar>
              <div class="pt-5">
                <vs-button color="primary" icon="cloud_download" :href="`${absoluteUrl()}/download?file_path=${shownFile.path}`">Download</vs-button>
              </div>
            </div>
            <div class="vx-col w-full sm:w-1/2">
              <div class="comment-box-container bg-white rounded-lg p-3">
                <order-comment-form :orderId="orderId" :file="shownFile"/>
              </div>
            </div>
          </div>
          <div class="mt-8">
            <h6 class="mb-3">Other files in this delivery</h6>
            <div class="gap-4 flex other-delivered-files">
              <div  v-for="(file, fIndex) in delivery.files" :key="fIndex"
                  @click="changeFile(fIndex)" class="file-box opacity-50" :class="{'opacity-100':file.id == shownFile.id}" >
                <div v-if="isImageFile(file.extension)" class="img h-full w-full"
                  :style="`background-image: url(${absoluteUrl(file.path)})`">
                </div>
                <div v-else class="others">
                  {{file.extension}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-popup>
  </div>
</template>

<script>
import OrderCommentForm from "@/components/forms/OrderCommentForm.vue"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
   props: {
    delivery: { 
      type: Object,
      default: null
    },
    orderId:{
      type: Number,
      required: true
    }
  },
  components: {
    OrderCommentForm,
    VuePerfectScrollbar
  },
  data() {
    return {
      shownFile: null,
      openPopup: false,
      settings: {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.70,
      },
    }
  },
  methods:{
    close(){
      this.shownFile = null;
      this.openPopup = false;
      this.$emit("closeMe")
    },
    changeFile(index){
      this.shownFile = this.delivery.files[index]
    }
  },
  mounted(){
    if(this.delivery && this.delivery.files.length){
      this.shownFile = this.delivery.files[this.delivery.index]
      this.openPopup = true
    }
  }
}
</script>

<style >

.delivery-file .file-box{
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.delivery-file .content-scroll-area {
      position: relative;
      margin: auto;
      width: 100%;
      height: 270px;
  }

@media(max-width:568px){
    .delivery-file .file-box{
      width: 50px;
      height: 50px;
    }
}

.delivery-file .file-box .img{
    background-position: center center;
    background-size: cover;
}

.delivery-file .file-box .others{
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  background: #eee;
}
.delivery-file .file-box .others:hover{
  background: #ccc;
}
</style>
