<template>
<vx-card no-shadow >
    <div class="order-activity">
        <div>
            <action-center :order="order" />
        </div>
        <div class="">
            <div class="timeline-2 mb-5">
                <div class="timeline-year">
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg></p>
                </div>
                <div class="timeline-continue">
                     <vs-collapse class="p-0">
                        <vs-collapse-item 
                            v-for="(activity, aIndex) in activities" 
                            :key="aIndex" :open="aIndex > activities.length - 5">
                            <div slot="header" >
                                <div class="timeline-header">
                                    <vs-avatar v-if="getCreator(activity.creator)" class="creator-avatar" :src="absoluteUrl(activity.creator.image)"/>
                                    <div v-else class="timeline-header-icon">
                                        <feather-icon :icon="activity.icon" class="h4 w-4" />
                                    </div>
                                    <div class="flex items-center">
                                        <h6 v-if="getCreator(activity.creator)" class="mb-1 mr-2 text-success" style="text-transform:capitalize;">{{ getCreator(activity.creator).name }}</h6>
                                        <span class="timeline-header-date">{{activity.created_at | date_time(1)}}</span>
                                    </div>
                                </div>
                                <vs-divider class="ml-4 my-0"/>
                            </div>
                            <div class="timeline-box-group">
                                <div v-if="activity.type == 'ORDER_CREATED'" class="">
                                    <div class="timeline-box">
                                        <div class="timeline-box-content">
                                            <h6 class="mb-2">{{ activity.title }}</h6>
                                            <div class="mb-0">
                                                You created this order and filled the requirements.
                                                <router-link class="text-success" :to="`/orders/${$route.params.orderId}?tab=2`">View the requirements</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="['ORDER_PAYMENT','ORDER_UPGRADE'].includes(activity.type)" class="">
                                    <div class="timeline-box">
                                        <div class="timeline-box-content">
                                            <h6 class="mb-2">{{ activity.title }}</h6>
                                            <div class="mb-0">
                                                {{activity.content}}
                                                <a class="text-success" target="_black" :href="activity.external_link">View payment receipt</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="activity.type == 'ORDER_DELIVERY'" class="">
                                    <div class="timeline-box">
                                        <div class="timeline-box-content">
                                            <h6 class="mb-2 timeline-heading-text py-1 px-2">#{{activity.spot}} {{ activity.title }}</h6>
                                            <div class="mb-2" v-html="preText(activity.content)"></div>
                                            <div>
                                                <order-attachments v-if="activity.files && activity.files.length"
                                                :files="activity.files" @showFile="openDelivery($event, activity.files)" columnClass="sm:w-1/2 sm:w-1/3" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="['ORDER_MESSAGE','ORDER_COMMENT','ORDER_REVISION'].includes(activity.type)" class="">
                                    <div class="timeline-box">
                                        <div class="timeline-box-content">
                                            <h6 v-if="activity.type == 'ORDER_REVISION'" class="mb-2 timeline-heading-text py-1 px-2">{{ activity.title }}</h6>
                                            <h6 v-else class="mb-2">{{ activity.title }}</h6>
                                            <div class="mb-2" v-html="preText(activity.content)"></div>
                                            <div>
                                                <order-attachments v-if="activity.files && activity.files.length"
                                                :files="activity.files" @showFile="openLightbox($event, activity.files)" columnClass="sm:w-1/2 sm:w-1/3" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="">
                                    <div class="timeline-box">
                                        <div class="timeline-box-content">
                                            <h6 class="mb-2">{{ activity.title }}</h6>
                                            <div v-html="activity.content" class="mb-0"> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </vs-collapse-item>
                    </vs-collapse>
                </div>                                        
            </div>
        </div>
        <div class="pt-12">
            <order-message-form :orderId="order.id"/> 
        </div>
        <div class="pt-12">
            <action-center
                :alertDealine="true"
                :alertDraft="true"
                :order="order" />
        </div>
        <CoolLightBox
            :items="lightboxFiles"
            :index="lightbox.index"
            :useZoomBar="true"
            :fullScreen="true"
            @close="lightbox.index = null">
        </CoolLightBox>
        <order-delivery-show v-if="delivery.show" :orderId="order.id"
         @closeMe="initializeAll()" :delivery="delivery" />
    </div>
</vx-card>
</template>

<script>
import ActionCenter from "./components/ActionCenter.vue"
import OrderMessageForm from "@/components/forms/OrderMessageForm.vue"
import OrderAttachments from "./components/OrderAttachments.vue"
import CoolLightBox from 'vue-cool-lightbox'
import OrderDeliveryShow from "./components/OrderDeliveryShow.vue"

export default {
    components: {
        ActionCenter,
        OrderMessageForm,
        OrderAttachments,
        CoolLightBox,
        OrderDeliveryShow 
    },
    data(){
        return {
            lightbox:{
                index: null,
                files: []
            },
            delivery:{
                show: false,
                index: null,
                files: []
            }
        }
    },
    computed: {
        order(){
            return this.$store.state.orderList.order
        },
        activities(){
            return this.order.activities
        },
        activeUser(){
            return this.$store.state.AppActiveUser
        },
        lightboxFiles(){
            return this.lightbox.files.map(file => {
                if(file.is_video){
                    return { title: file.name,
                      thumb: this.absoluteUrl(file.path),
                      mediaType: 'video',
                      src: this.absoluteUrl(file.video_path)
                    }
                }else{
                  return { title: file.name,
                      mediaType: 'image',
                      src: this.absoluteUrl(file.path)
                   }
                }
            })
        },
    },
    methods:{
        getCreator(user){
            if(!user) return null
            return {
                name: user.id == this.activeUser.id ? "Me" : user.first_name || user.username,
                image: user.image
            }
        },
        openLightbox(index, files){
            this.lightbox.files = [...files],
            this.lightbox.index = index
        },   
        openDelivery(index, files){
            this.delivery.files = [...files];
            this.delivery.show = true;
            this.delivery.index = index;
        },
        initializeAll(){
            setTimeout(() =>{
                this.delivery.show = false
            },1000)
        }  
    }
}
</script>

<style lang="scss" >
.activity-collapse .vs-collapse-item--header{
 padding-left:0;
 padding-right:0;
}

.timeline-2 {
    position: relative;

    &::after {
        position: absolute;
        content: "";
        width: 2px;
        height: 100%;
        top: 0px;
        left: 16px;
        margin-left: -1px;
        background: #ececec;
    }
    .vs-collapse-item--header{
        padding: 1rem 0;
        padding-bottom: 0;
    }
    .con-content--item{
        padding-top:0;
    }
    .vs-collapse-item{
        border:none;
    }

    .vs-divider .vs-divider-border{
        border-top: 1px solid rgb(0 0 0 / 4%) !important;
    }
    .timeline-year {
        position: relative;
        width: 100%;
        text-align: left;
        z-index: 1;
        margin-left: -6px;
        p {
            display: inline-flex;
            width: 45px;
            height: 45px;
            padding: 23px 10px;
            font-size: 18px;
            color: #444;
            margin: 0;
            padding: 23px 10px;
            background: #ececec;
            border-radius: 50px;
            text-transform: uppercase;
            align-items: center;
            justify-content: center;
            text-align: center;
            span {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .timeline-box {
        position: relative;
        margin: 0px 0px 10px 30px;
        background: #ffffff;
        .timeline-box-content {
            position: relative;
        }
    }
}

.timeline-header{
    display: flex;
    z-index: 2;
    align-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 1rem;

    .creator-avatar{
        margin-right: 1rem;
        margin-left: 0px;
    }
    .timeline-header-date{
        font-size: 10px;
    }

    .timeline-header-icon{
        background: #e0f2f1;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 1rem;
    }
}
.timeline-heading-text{
    background-color: #f7fafc;
    border: 2px solid #e5e7e8
}

</style>