<template>
  <div>
    <div class="">
        <vs-textarea class="w-full" rows="2" name="content" v-validate="{required: true, max:1000}" 
            counter="1000" label="Write your request" v-model="data.content" />
        <span class="text-danger text-sm"  v-show="errors.has('content')">{{ errors.first('content') }}</span>
        <vue-dropzone v-if="popupAttachment" id="revisionUploadDrop" ref="revisionUploadDrop"
        :use-custom-slot="true">
            <div class="needsclick m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                <h4>Attach any reference file here (if any).</h4>
                <span class="text-primary font-13">
                    <em>Maximum of 10 files</em>
                </span>
            </div>
        </vue-dropzone>
    </div>
    <div class="flex flex-wrap mt-3 items-center justify-end">
        <vs-button radius color="dark" @click.prevent="popupAttachment = !popupAttachment"  class="inline mr-2" type="line" icon-pack="feather" icon="icon-paperclip"></vs-button>
        <vs-button class="mt-2" @click.prevent="create()">Send request</vs-button>
    </div>
  </div>
</template>

<script>
import VueDropzone from "../VueDropzone.vue";
export default {
    components:{
        VueDropzone,
    },
    props:{
        orderId:{
            type: Number,
            required:true
        }
    },
    data() {
        return {
            popupAttachment:false,
            data:{
                title:"Revision request",
                content:"",
                icon: "RepeatIcon",
                order_id: this.orderId,
                type: "ORDER_REVISION",
                url: `/orders/${this.orderId}`
            },
        }
    },
    methods: {
        create() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData();
                    formData.append('data', JSON.stringify(this.data))
                    if(this.collectFiles().length){
                       this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
                    }
                    this.$vs.loading({ color: "#444", type: "sound"})
                    this.$http.post(`/activities/${this.collectFiles().length ? 'attachments':'create'}`, formData)
                    .then((response) => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.dispatch("orderList/fetchOrder",this.orderId)
                            this.$emit("closeMe");
                        }
                    })
                }
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.revisionUploadDrop){
                arrafile = [...this.$refs.revisionUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
    }
}

</script>
