<template>
  <div>
    <vs-alert color="success" class="mb-4" title="Your delivery is here!" v-if="order.status === 'delivered'">
        <span class="block">View the delivery to make sure you have exactly what you need. Let us know your thoughts?</span>
        <vs-button class="mt-2 mr-2" @click.prevent="popupRevision = true" color="warning">Ask for Revision</vs-button>
        <vs-button class="mt-2 " @click.prevent="confirmMarkCompleted" color="success">Yes, I approve delivery</vs-button>
    </vs-alert>
    <div v-if="alertDraft && order.status == 'draft'" class="con-vs-alert mb-4 con-vs-alert-warning">
        <div class="vs-alert sm:px-4">
            <h4 class="titlex h3 mb-2 shadow-none text-warning">Saved as draft!</h4>
            <div class="mb-3">This order was saved as draft. You can proceed to make adjustment (if any) for the order to be processed.</div>
            <div class="py-4">
                <a :href="absoluteUrl(`/projects/pricing/${order.number}`)" target="_blank" name="button" class="vs-component mt-2 vs-button vs-button-primary vs-button-filled">
                    <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                <span class="vs-button-text vs-button--text">Pay now</span><span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span></a>
            </div>
        </div>
    </div>
    <vs-alert color="warning" class="mb-4" title="Order Satisfied?" :active.sync="isNotifyReviewal" >
        <span>Write a brief review and rate this order. Your feedback means a lot to us. Thanks.<vs-button class="ml-4 mt-2" type="border" size="small" @click.prevent="popupAddReview = true" color="warning">Rate Now!</vs-button> <!-- <router-link :to=""> Rate Now!</router-link> --> </span>
    </vs-alert>
    <vs-popup v-if="isNotifyReviewal" class="holamundo" @close="initializeAll" title="Submit Review" :active.sync="popupAddReview">
        <div class="mt-2">
            <review-form />
        </div>
    </vs-popup>
    <vs-alert color="warning" class="mb-4" title="Note!" v-if="alertDealine && order.status == 'delivered'">
        <div class="text-primary text-sm flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather mr-2 feather-watch"><circle cx="12" cy="12" r="7"></circle><polyline points="12 9 12 12 13.5 13.5"></polyline><path d="M16.51 17.35l-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7l.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83"></path></svg>
            <span> Keep in mind that you have until <b>{{deliveryExpired(order.delivered_at) | date_time(1)}}</b> to approve this delivery or request a revision. After this date, the order will be finalized and marked as complete</span>
        </div>
    </vs-alert>
    <vs-popup v-if="popupRevision" class="holamundo" @close="initializeAll"
     title="Revision request" :active.sync="popupRevision">
        <order-revision-form  v-if="popupRevision"
        :orderId="order.id" @closeMe="initializeAll"/>
    </vs-popup>
  </div>
</template>

<script>
import ReviewForm from "./ReviewForm.vue"
import OrderRevisionForm from "@/components/forms/OrderRevisionForm.vue"

export default {
    props:{
        order:{
            type:Object,
            required: true
        },
        alertDealine:{
            default: false
        },
        alertDraft:{
            default: true
        }
    },
    components:{
        ReviewForm,
        OrderRevisionForm
    },
    data(){
        return{
            popupAddReview: false,
            popupRevision: false,
        }
    },
    computed:{
        isNotifyReviewal(){
            return false //this.order.status === 'completed' && !this.order.is_reviewed;
        },
        deliveryExpired(){
            return (date) => new Date().setTime(new Date(date).getTime() + (parseInt(10*24)*60*60*1000))
        },
    },
    methods:{
        confirmMarkCompleted() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'warning',
                title: 'Are you sure?',
                text: 'You are about to mark this order completed.' ,
                accept: this.markAsCompleted,
                acceptText: "Proceed",
            })
        },
        markAsCompleted() {
            let formData = new FormData()
            formData.append('data', JSON.stringify({
                "order_id": this.order.id,
                "title":"Order completed",
                "content":"Order has been approved and marked completed",
                "icon": "CheckSquareIcon",
                "type": "ORDER_COMPLETED",
                "url": `/orders/${this.order.id}`
            }))
            this.$vs.loading({color: "#444", type: "sound"})
            this.$http.post(`/activities/create`, formData)
                .then(response => {
                this.$vs.loading.close()
                if(response.data.success){
                    this.$store.dispatch("orderList/fetchOrder",this.order.id)
                }
            })
        },
        initializeAll(){
            this.popupAddReview = false;
            this.popupRevision = false
        },
    }
}
</script>

<style>

</style>